<template>
  <div class="product-components-core-technology-wrapper">
    <div class="section-one">
      <div class="section-one-main">
        <div>
          <div>{{ $t('coreTechnologyPage.sectionOne.title') }}</div>
          <div>{{ $t('coreTechnologyPage.sectionOne.des') }}</div>
        </div>
      </div>
    </div>
    <div class="section-two">
      <div class="title">
        {{ $t('coreTechnologyPage.sectionTwo.title') }}
      </div>
      <div class="section-two-swiper">
        <swiper
          :options="swiperOptions.sectionTwoSwiper"
        >
          <template
            v-for="(item, index) in sectionTwoList"
          >
            <swiper-slide
              class="section-two-swiper-slide"
              :key="index"
            >
              <div class="section-two-swiper-slide-img">
                <img :src="item.imgage" />
              </div>
            </swiper-slide>
          </template>
        </swiper>
        <div class="section-two-swiper-navigation">
          <div>
            <div class="section-two-swiper-button-prev">
              <img src="@/assets/image/product/ss3.png">
            </div>
            <div class="section-two-swiper-button-next">
              <img src="@/assets/image/product/ss3.png">
            </div>
          </div>
        </div>

        <div class="section-two-postion">
           {{ $t('coreTechnologyPage.sectionTwo.des') }}
           <div class="line"></div>
        </div>
        <div class="section-two-wiper-pagination"></div>
      </div>
    </div>
    <div class="section-three">
      <div class="title">{{ $t('coreTechnologyPage.sectionThree.title') }}</div>
      <div class="des">{{ $t('coreTechnologyPage.sectionThree.des') }}</div>
      <div class="section-three-swiper">
        <swiper
          :options="swiperOptions.sectionThreeSwiper"
        >
          <template
            v-for="(item, index) in sectionThreeList"
          >
            <swiper-slide
              class="section-three-swiper-slide"
              :key="index"
            >
              <div class="section-three-swiper-slide-img-top">
                <img :src="item.top" />
              </div>
            </swiper-slide>
          </template>
        </swiper>
        <div class="section-three-wiper-pagination"></div>
        <div class="section-three-swiper-slide-img-bottom">
          <img src="@/assets/image/product/ss2.jpg" />
        </div>
      </div>
    </div>
    <div class="section-four">
      <div class="banner">
        <div class="content">
          <div>
            <div>{{$t('coreTechnologyPage.sectionFour.title')}}</div>
            <div>{{$t('coreTechnologyPage.sectionFour.des')}}</div>
          </div>
          <div>{{$t('coreTechnologyPage.sectionFour.des2')}}</div>
        </div>
      </div>
      <div class="section-four-content">
        <div>
          <div class="pic-one">
            <div>
              <div>
                <img src="@/assets/image/product/ss5.jpg" />
              </div>
              <div>{{ $t('coreTechnologyPage.picOne.title') }}</div>
              <div>{{ $t('coreTechnologyPage.picOne.des') }}</div>
            </div>
            <div>
              <div>
                <img src="@/assets/image/product/ss6.jpg" />
              </div>
              <div>{{ $t('coreTechnologyPage.picOneTwo.title') }}</div>
              <div>{{ $t('coreTechnologyPage.picOneTwo.des') }}</div>
            </div>
          </div>
          <div class="pic-two">
            <div>
              <div>
                <img src="@/assets/image/product/ss.jpg" />
              </div>
              <div>{{ $t('coreTechnologyPage.picOneThree')[0] }}</div>
            </div>
            <div>
              <div>
                <img src="@/assets/image/product/ss7.jpg" />
              </div>
              <div>{{ $t('coreTechnologyPage.picOneThree')[1] }}</div>
            </div>
            <div>
              <div>
                <img src="@/assets/image/product/ss8.jpg" />
              </div>
              <div>{{ $t('coreTechnologyPage.picOneThree')[2] }}</div>
            </div>
          </div>
          <div class="pic-three">
            <div
              @click="urlList = [item]; imgPrewShow = true"
              v-for="(item, index) in urlArray"
              :key="index"
            >
              <img :src="item" />
              <div>
                <img src="@/assets/image/product/ss5.png" />
              </div>
            </div>
            <img-prew-view
              v-if="imgPrewShow"
              :url-list="urlList"
              :on-close="() => {imgPrewShow = false}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { configProduct } from '@/utils/config'
import ImgPrewView from '@/components/image/image-viewer.vue'
export default {
  components: {
    Swiper,
    SwiperSlide,
    ImgPrewView
  },
  data() {
    console.log(configProduct.EcoreTechnologySwiperThree)
    return {
      sectionTwoList: configProduct.EcoreTechnologySwiper,
      swiperOptions: {
        sectionTwoSwiper: {
          loop: true,
          slidesPerView: "auto",
          centeredSlides: true,
          spaceBetween: 85,
          pagination: {
            el: '.section-two-wiper-pagination',
            clickable: true
          },
          navigation: {
            prevEl: '.section-two-swiper-button-prev',
            nextEl: '.section-two-swiper-button-next'
          }
        },
        sectionThreeSwiper: {
          loop: true,
          pagination: {
            el: '.section-three-wiper-pagination'
          }
        }
      },
      sectionThreeList: configProduct.EcoreTechnologySwiperThree,
      imgPrewShow: false,
      urlArray: [
        require('@/assets/image/product/ss9.jpg'),
        require('@/assets/image/product/ss10.jpg'),
        require('@/assets/image/product/ss11.jpg'),
        require('@/assets/image/product/ss12.jpg')
      ],
      urlList: []
    }
  },
}
</script>

<style lang="scss" scoped>
.product-components-core-technology-wrapper {
  width: 100%;
  .section-one {
    width: 100%;
    background: #fff;
    height: 626px;
    overflow: hidden;
    .section-one-main {
      max-width: 1200px;
      height: 626px;
      background: url('~@/assets/image/product/ss1.jpg') no-repeat right bottom;
      margin: 0 auto;
      &>div {
        max-width: 550px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &>div:nth-child(1) {
          color: rgba(34, 34, 34, 1);
          font-size: 42px;
          line-height: 54px;
          margin-bottom: 40px;
        }
        &>div:nth-child(2) {
          font-size: 18px;
          line-height: 32px;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
  }
  .section-two {
    background: #F5F6F7;
    width: 100%;
    height: 885px;
    overflow: hidden;
    position: relative;
    .title {
      margin: 80px 0;
      text-align: center;
      color: rgba(34, 34, 34, 1);
      font-size: 42px;
      line-height: 54px;
      font-weight: bold;
    }
    .section-two-swiper {
      width: 100%;
      .section-two-swiper-slide {
        width: 1200px;
        .section-two-swiper-slide-img {
          width: 1200px;
          height: 463px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .section-two-postion {
      width: 431px;
      height: 509px;
      overflow: hidden;
      pointer-events: none;
      background: url('~@/assets/image/product/ss2.png');
      position: absolute;
      bottom: 86px;
      z-index: 9;
      left: calc(((100% - 1200px) / 2) + 68px);
      padding: 70px;
      color: #333333;
      line-height: 32px;
      font-size: 18px;
      .line {
        position: absolute;
        width: 116px;
        height: 2px;
        background: #000000;
        bottom: 123px;
        left: 70px;
      }
    }
    .section-two-wiper-pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 12px;
      margin-top: 117px;
      ::v-deep {
        &>span {
        display: block;
        width: 12px;
        height: 12px;
        background: rgba(228, 228, 228, 1);
        margin: 0 9px;
        opacity: 1;
        border-radius: 0;
        &.swiper-pagination-bullet-active {
          background: rgba(51, 51, 51, 1);
        }
      }
      }
    }
    .section-two-swiper-navigation {
      position: absolute;
      width: 100%;
      top: 430px;
      display: flex;
      left: 0;
      justify-content: center;
      z-index: 11;
      &>div {
        max-width: 1318px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        &>div {
          cursor: pointer;
          &:nth-child(1) {
          transform: rotate(180deg);
        }
        }
      }
    }
  }
  .section-three {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 906px;
    overflow: hidden;
    .section-three-wiper-pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 12px;
      margin-top: -65px;
      z-index: 2;
      position: relative;
      ::v-deep {
        &>span {
        display: block;
        width: 12px;
        height: 12px;
        background: rgba(228, 228, 228, 1);
        margin: 0 9px;
        opacity: 1;
        border-radius: 0;
        &.swiper-pagination-bullet-active {
          background: rgba(51, 51, 51, 1);
        }
      }
      }
    }
    .title {
      font-weight: 600;
      color: #222222;
      line-height: 54px;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 40px;
      font-size: 42px;
    }
    .des {
      max-width: 1000px;
      text-align: center;
      font-size: 18px;
      color: rgba(51, 51, 51, 1);
      line-height: 32px;
      margin: 0 auto;
      margin-bottom: 70px;
    }
    .section-three-swiper-slide-img-bottom {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 387px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      top: 83px;
    }
    .section-three-swiper {
      height: 471px;
      width: 100%;
      position: relative;
      z-index: 2;
      .section-three-swiper-slide {
        width: 100%;
        height: 471px;
        position: relative;
        .section-three-swiper-slide-img-top {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 366px;
          position: relative;
          z-index: 10;
          align-items: center;
        }
      }

    }
  }
  .section-four {
    width: 100%;
    position: relative;
    .banner {
      width: 100%;
      height: 481px;
      background: rgba(96, 96, 101, 1);
      .content {
        width: 100%;
        max-width: 1200px;
        height: 100%;
        background: url('~@/assets/image/product/ss4.jpg') no-repeat right bottom;
        margin: 0 auto;
        &>div:nth-child(1) {
          max-width: 500px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 340px;
          &>div:nth-child(1) {
            font-size: 38px;
            color: rgba(255, 255, 255, 1);
            font-weight: bold;
          }
          &>div:nth-child(2) {
            font-size: 18px;
            color: #fff;
            line-height: 32px;
            margin-top: 22px;
          }
        }
        &>div:nth-child(2) {
          font-size: 18px;
          color: #fff;
          line-height: 32px;
        }
      }
    }
    .section-four-content {
      width: 100%;
      height: 1391px;
      background: url('~@/assets/image/product/ss4.png') no-repeat;
      background-size: 100% 100%;
      position: relative;
      &>div {
        position: relative;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        .pic-one {
          height: 452px;
          display: flex;
          width: 100%;
          justify-content: space-between;
          left: 0;
          top: -77px;
          position: absolute;
          &>div {
            width: 49%;
            height: 100%;
            background: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &>div:nth-child(1) {
              width: 42%;
              height: 252px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &>div:nth-child(2) {
              color: rgba(0, 0, 0, 1);
              font-size: 18px;
              line-height: 32px;
              margin: 16px 0;
              font-weight: bold;
              margin-bottom: 4px;
            }
            &>div:nth-child(3) {
              color: #333333;
              line-height: 32px;
            }
          }
        }
        .pic-two {
          display: flex;
          justify-content: space-between;
          padding-top: 404px;
          &>div{
            width: 32.33%;
            height: 413px;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &>div:nth-child(1) {
              width: 70%;
              height: 253px;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &>:nth-child(2) {
              color: rgba(51, 51, 51, 1);
              font-size: 18px;
              line-height: 32px;
              margin-top: 43px;
            }
          }
        }
        .pic-three {
          width: 100%;
          justify-content: space-between;
          display: flex;
          margin-top: 40px;
          &>div {
            display: flex;
            width: 23.33%;
            height: 373px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            &>div {
              opacity: 0;
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba(0, 0, 0, 0.4);
              transition: all 0.3s;
              &:hover {
                opacity: 1;
                transition: all 0.3s;
              }
            }
          }
        }
      }
    }
  }
}
</style>